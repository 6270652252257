<template>
  <b-overlay rounded="sm" :show="loading">
    <b-card class="m-5">
      <div>
        <div class="text-center">
          <div class="my-2">
            <p>Please provide the information below to setup your account</p>
          </div>
        </div>

        <b-alert variant="danger" show>
          <div v-if="errorMessage" class="alert-body font-small-2">
            <b-card-text>
              <feather-icon icon="AlertCircleIcon" />
              <small>{{ errorMessage }}</small>
            </b-card-text>
          </div>
        </b-alert>

        <div>
          <validation-observer ref="residentialForm">
            <b-form @submit.prevent="saveOnboardingData">
              <div class="d-flex align-items-center justify-content-center">
                <b-col cols="12" md="6">
                  <b-col cols="12" md="12">
                    <h3 class="mb-2">
                      Personal Information
                    </h3>

                    <b-form-group label="First Name" label-for="first_name">
                      <template #label>
                        <span>First Name<span style="color: tomato">*</span></span>
                      </template>

                      <validation-provider #default="{ errors }" name="First Name" rules="required">
                        <div class="custom-input">
                          <span class="leading">
                            <feather-icon icon="UserIcon" size="20" />
                          </span>
                          <input id="first_name" v-model="first_name" placeholder="Enter your First Name Here">
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group label="Last Name" label-for="last_name">
                      <template #label>
                        <span>Last Name<span style="color: tomato">*</span></span>
                      </template>

                      <validation-provider #default="{ errors }" name="Last Name" rules="required">
                        <div class="custom-input">
                          <span class="leading">
                            <feather-icon icon="UserIcon" size="20" />
                          </span>
                          <input id="last_name" v-model="last_name" placeholder="Enter your Last Name Here" height="20px">
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group label="Gender" label-for="gender" class="mt-2">
                      <template #label>
                        <span>Gender<span style="color: tomato">*</span></span>
                      </template>

                      <validation-provider #default="{ errors }" name="gender" rules="required">
                        <v-select
                          id="gender"
                          v-model="gender"
                          :loading="loading"             
                          :clearable="false"
                          :options="genderOptions"
                          :reduce="val => val.value"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        >
                          <span slot="no-options">Select gender</span>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  
                  <b-col cols="12" md="12">
                    <h3 class="mb-2 mt-5">
                      Church Information
                    </h3>

                    <b-form-group label="Church Level" label-for="church_level">
                      <template #label>
                        <span>Church Level<span style="color: tomato">*</span></span>
                      </template>
  
                      <validation-provider #default="{ errors }" name="church_level" rules="required">
                        <p>Select the church level to receive payments.</p>
                        <v-select
                          id="church_level"
                          v-model="church_level"
                          :loading="loading"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="churchLevelOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                        >
                          <span slot="no-options">Select church level</span>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
  
                    <b-form-group v-if="['assembly', 'district', 'area'].includes(church_level)" label="Region" label-for="region">
                      <template #label>
                        <span>Region<span style="color: tomato">*</span></span>
                      </template>
  
                      <validation-provider #default="{ errors }" name="region" rules="required">
                        <v-select
                          id="region"
                          v-model="church_region"
                          :loading="loading"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="churchRegionOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                        >
                          <span slot="no-options">Select church region</span>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
  
                    <b-form-group v-if="['assembly', 'district', 'area'].includes(church_level)" label="Area" label-for="area">
                      <template #label>
                        <span>Area<span style="color: tomato">*</span></span>
                      </template>
  
                      <validation-provider #default="{ errors }" name="area" rules="required">
                        <v-select
                          id="area"
                          v-model="church_area"
                          :loading="loading"             
                          :clearable="false"
                          :options="churchAreaOptions"
                          :reduce="val => val.value"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        >
                          <span slot="no-options">{{ !!church_region ? 'Select church area' : 'Select church region first' }}</span>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
  
                    <b-form-group v-if="['assembly', 'district'].includes(church_level)" label="District" label-for="region">
                      <template #label>
                        <span>District<span style="color: tomato">*</span></span>
                      </template>
  
                      <validation-provider #default="{ errors }" name="district" rules="required">
                        <v-select
                          id="district"
                          v-model="church_district"
                          :loading="loading"             
                          :clearable="false"
                          :options="churchDistrictOptions"
                          :reduce="val => val.value"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        >
                          <span slot="no-options">{{ !!church_area ? 'Select church district' : 'Select church area first' }}</span>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
  
                    <b-form-group v-if="church_level === 'assembly'" label="Assembly" label-for="assembly">
                      <template #label>
                        <span>Assembly<span style="color: tomato">*</span></span>
                      </template>
  
                      <validation-provider #default="{ errors }" name="assembly" rules="required">
                        <v-select
                          id="assembly"
                          v-model="church_assembly"
                          :loading="loading"             
                          :clearable="false"
                          :options="churchAssemblyOptions"
                          :reduce="val => val.value"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        >
                          <span slot="no-options">{{ !!church_district ? 'Select church assembly' : 'Select church district first' }}</span>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="12">
                    <h3 class="mb-2 mt-5">
                      Bank Information
                    </h3>

                    <b-form-group label="Bank Country" label-for="bank_level">
                      <template #label>
                        <span>Bank Country<span style="color: tomato">*</span></span>
                      </template>
  
                      <validation-provider #default="{ errors }" name="bank_country" rules="required">
                        <v-select
                          id="bank_country"
                          v-model="bank_country"
                          :loading="loading"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="countryOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                        >
                          <span slot="no-options">Select bank country</span>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
  
                    <b-form-group label="Bank Name" label-for="bank_name">
                      <template #label>
                        <span>Bank Name<span style="color: tomato">*</span></span>
                      </template>
  
                      <validation-provider #default="{ errors }" name="bank_name" rules="required">
                        <v-select
                          id="bank_name"
                          v-model="bank_code"
                          :loading="loading"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="bankOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                        >
                          <span slot="no-options">{{ !!bank_country ? 'Select bank name' : 'Select bank country first' }}</span>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group label="Account / Mobile Money Number" label-for="bank_account_number">
                      <template #label>
                        <span>Account / Mobile Money Number<span style="color: tomato">*</span></span>
                      </template>

                      <validation-provider #default="{ errors }" name="Account / Mobile Money Number" rules="required">
                        <div class="custom-input">
                          <span class="leading">
                            <feather-icon icon="BriefcaseIcon" size="20" />
                          </span>
                          <input id="bank_account_number" v-model="bank_account_number" placeholder="Enter your Account / Mobile Money Number Here">
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

  
                    <div class="d-flex my-1 mt-5 justify-content-between align-items-center">
                      <div>
                        <b-button type="submit" variant="primary" block class="py-1">
                          Proceed
                          <feather-icon icon="ChevronRightIcon" />
                        </b-button>
                      </div>
  
                      <b-card-text class="text-right mt-0 text-danger cursor-pointer" @click="logout">
                        <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
                        <span>Logout</span>
                      </b-card-text>
                    </div>
                  </b-col>
                </b-col>

              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import { get } from "lodash";
import { required } from '@validations';
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import vSelect from 'vue-select'
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BCol,
  BImg,
  BRow,
  BForm,
  BLink,
  BAlert,
  BButton,
  BOverlay,
  BCard,
  BCardText,
  BCardTitle,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormDatepicker
} from 'bootstrap-vue'

export default {
  components: {
    vSelect,

    AuthWrapper,
    BOverlay,
    BAlert,
    BCard,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    BFormSelect,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
      errorMessage: "",
      hasErrorMessage: false,

      first_name: '',
      last_name: '',
      gender: '',
      church_level: '',
      genderOptions: [
        {
          value: "female",
          label: "Female"
        },
        {
          value: "male",
          label: "Male"
        }
      ],

      bank_country: '',
      bank_code: '',
      bank_account_number: '',

      church_region: '',
      church_district: '',
      church_area: '',
      church_assembly: '',

      bankOptions: [],
      countryOptions: [],
      churchRegionOptions: [],
      churchAreaOptions: [],
      churchDistrictOptions: [],
      churchAssemblyOptions: [],

      // validation
      required,
    }
  },
  watch: {
    church_region: {
      handler(changed){
        if (changed){
          this.fetchChurchAreas();
        }
      },
      immediate: true
    },
    church_area: {
      handler(changed){
        if (changed){
          this.fetchChurchDistrict();
        }
      },
      immediate: true
    },
    church_district: {
      handler(changed){
        if (changed){
          this.fetchChurchAssemblies();
        }
      },
      immediate: true
    },
    bank_country: {
      handler(changed){
        if (changed){
          this.fetchBanks();
        }
      },
      immediate: true
    }
  },
  created() {
    this.fetchCountries();
    this.fetchChurchRegions();
  },
  mounted() {
    this.first_name = this.getValueFromSource(this.currentUser, 'first_name');
    this.last_name = this.getValueFromSource(this.currentUser, 'last_name');
    this.gender = this.getValueFromSource(this.currentUser, 'gender');

    const churchProfile = this.currentUser.church_profile;

    this.bank_country = this.getValueFromSource(churchProfile, 'bank_country');
    this.bank_code = this.getValueFromSource(churchProfile, 'bank_code');
    this.bank_account_number = this.getValueFromSource(churchProfile, 'bank_account_number');

    this.church_level = this.getValueFromSource(churchProfile, 'church_level');
    this.church_region = this.getValueFromSource(churchProfile, 'church_region');
    this.church_district = this.getValueFromSource(churchProfile, 'church_district');
    this.church_area = this.getValueFromSource(churchProfile, 'church_area');
    this.church_assembly = this.getValueFromSource(churchProfile, 'church_assembly');
  },
  methods: {
    async fetchCountries() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchCountries({ criteria: 'all' });
        const { data } = request.data;
        this.countryOptions = data.map(country => ({
          label: country.country,
          value: country.country.toLowerCase()
        }));

        this.bank_name = '';
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchChurchRegions() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchChurchRegions();
        const { data } = request.data;
        this.churchRegionOptions = data.map(opt => ({
          label: opt.name,
          value: opt.name
        }));

        this.church_region = '';
        this.church_area = '';
        this.church_district = '';
        this.church_assembly = '';
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchChurchAreas() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchChurchAreas(this.church_region);
        const { data } = request.data;
        this.churchAreaOptions = data.map(opt => ({
          label: opt.name,
          value: opt.name
        }));

        this.church_area = '';
        this.church_district = '';
        this.church_assembly = '';
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchChurchDistrict() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchChurchDistricts(this.church_region, this.church_area);
        const { data } = request.data;
        this.churchDistrictOptions = data.map(opt => ({
          label: opt.name,
          value: opt.name
        }));

        this.church_district = '';
        this.church_assembly = '';
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchChurchAssemblies() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchChurchAssemblies(this.church_region, this.church_area, this.church_district);
        const { data } = request.data;
        this.churchAssemblyOptions = data.map(opt => ({
          label: opt.name,
          value: opt.name
        }));

        this.church_assembly = '';
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchBanks() {
      try {
        this.loading = true;

        if (!this.bank_country){
          this.bankOptions = [];
          return;
        }

        const query = {
          country: this.bank_country
        }

        const request = await this.useJwt().sharedService.fetchBanks(query);
        const { data } = request.data;
        this.bankOptions = data.map(opt => ({
          label: opt.name,
          value: opt.code
        }));
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async saveOnboardingData() {
      try {
        this.loading = true;

        const success = await this.$refs.residentialForm.validate();
        if (!success) {
          return;
        }

        const {
          gender,
          first_name,
          last_name,
          bank_code,
          church_area,
          bank_country,
          church_level,
          church_region,
          church_assembly,
          church_district,
          bank_account_number,
        } = this;

        const payload = {
          gender,
          first_name,
          last_name,
          bank_code,
          church_area,
          bank_country,
          church_level,
          church_region,
          church_assembly,
          church_district,
          bank_account_number,
        }

        await this.useJwt().authService.saveChurchOnboardingData(payload);

        this.$router.replace({
            name: "church-home"
          })
          .catch(() => {});

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'AlertQuestionIcon',
            variant: 'success',
            text: `Thank you for providing your information. Your account is pending verification. Contact HQ to approve your account.`
          },
        }, {
          timeout: 1000
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.errorMessage = error_message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    onRegionsFetch(data) {
      this.cityOptions = [];
      this.townOptions = [];
      this.region = '';
      this.city = '';
      this.town = '';

      this.regionOptions = data.map(geo_data => ({
        label: geo_data.name,
        value: geo_data.geonameId
      }));
    },
    onCitiesFetch(data) {
      this.townOptions = [];
      this.city = '';
      this.town = '';

      this.cityOptions = data.map(geo_data => ({
        label: geo_data.name,
        value: geo_data.geonameId
      }));
    },
    onTownsFetch(data) {
      this.town = '';
      this.townOptions = data.map(geo_data => ({
        label: geo_data.name,
        value: geo_data.geonameId
      }));
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.custom-input {
  position: relative;
  display: block;
  color:  #80A0AF !important;

  .leading{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    padding-left: 12px;
    padding-right: 0.5rem;
    display: flex;
    align-items: center;
  }

  .trailing {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding-right: 12px;
    padding-left: 0.5rem;
    display: flex;
    align-items: center;
  }

  .otp {
    letter-spacing: 2rem;
    font-weight: bold;
    text-align: center !important;
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
    padding-left: 3.5rem !important;
  }


  input, textarea, select {
    border: 1px solid $neutral;
    border-radius: 5px;
    width: 100%;
    top: 50%;
    bottom: 50%;
    min-width: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 2.75rem;
    color:  #80A0AF !important;

    &:focus {
      outline: none;
      border: 1px solid $main-color;
    }

    &::placeholder {
      color: $neutral;
      opacity: 0.3;
    }
    &:invalid {
      border-color: red;
    }
    &:required {
      border-color: red;
    }
    &:-ms-input-placeholder {
      color: $neutral;
    }
  }
}
</style>
