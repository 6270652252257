var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"rounded":"sm","show":_vm.loading}},[_c('b-card',{staticClass:"m-5"},[_c('div',[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"my-2"},[_c('p',[_vm._v("Please provide the information below to setup your account")])])]),_c('b-alert',{attrs:{"variant":"danger","show":""}},[(_vm.errorMessage)?_c('div',{staticClass:"alert-body font-small-2"},[_c('b-card-text',[_c('feather-icon',{attrs:{"icon":"AlertCircleIcon"}}),_c('small',[_vm._v(_vm._s(_vm.errorMessage))])],1)],1):_vm._e()]),_c('div',[_c('validation-observer',{ref:"residentialForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveOnboardingData.apply(null, arguments)}}},[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('h3',{staticClass:"mb-2"},[_vm._v(" Personal Information ")]),_c('b-form-group',{attrs:{"label":"First Name","label-for":"first_name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("First Name"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"custom-input"},[_c('span',{staticClass:"leading"},[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"20"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.first_name),expression:"first_name"}],attrs:{"id":"first_name","placeholder":"Enter your First Name Here"},domProps:{"value":(_vm.first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.first_name=$event.target.value}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Last Name","label-for":"last_name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Last Name"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"custom-input"},[_c('span',{staticClass:"leading"},[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"20"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.last_name),expression:"last_name"}],attrs:{"id":"last_name","placeholder":"Enter your Last Name Here","height":"20px"},domProps:{"value":(_vm.last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.last_name=$event.target.value}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Gender","label-for":"gender"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Gender"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"gender","loading":_vm.loading,"clearable":false,"options":_vm.genderOptions,"reduce":function (val) { return val.value; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Select gender")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('h3',{staticClass:"mb-2 mt-5"},[_vm._v(" Church Information ")]),_c('b-form-group',{attrs:{"label":"Church Level","label-for":"church_level"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Church Level"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"church_level","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',[_vm._v("Select the church level to receive payments.")]),_c('v-select',{attrs:{"id":"church_level","loading":_vm.loading,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.churchLevelOptions,"reduce":function (val) { return val.value; },"clearable":false},model:{value:(_vm.church_level),callback:function ($$v) {_vm.church_level=$$v},expression:"church_level"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Select church level")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(['assembly', 'district', 'area'].includes(_vm.church_level))?_c('b-form-group',{attrs:{"label":"Region","label-for":"region"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Region"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,false,3915155647)},[_c('validation-provider',{attrs:{"name":"region","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"region","loading":_vm.loading,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.churchRegionOptions,"reduce":function (val) { return val.value; },"clearable":false},model:{value:(_vm.church_region),callback:function ($$v) {_vm.church_region=$$v},expression:"church_region"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Select church region")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,162184035)})],1):_vm._e(),(['assembly', 'district', 'area'].includes(_vm.church_level))?_c('b-form-group',{attrs:{"label":"Area","label-for":"area"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Area"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,false,2943210320)},[_c('validation-provider',{attrs:{"name":"area","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"area","loading":_vm.loading,"clearable":false,"options":_vm.churchAreaOptions,"reduce":function (val) { return val.value; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.church_area),callback:function ($$v) {_vm.church_area=$$v},expression:"church_area"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(!!_vm.church_region ? 'Select church area' : 'Select church region first'))])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1978258534)})],1):_vm._e(),(['assembly', 'district'].includes(_vm.church_level))?_c('b-form-group',{attrs:{"label":"District","label-for":"region"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("District"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,false,3846550881)},[_c('validation-provider',{attrs:{"name":"district","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"district","loading":_vm.loading,"clearable":false,"options":_vm.churchDistrictOptions,"reduce":function (val) { return val.value; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.church_district),callback:function ($$v) {_vm.church_district=$$v},expression:"church_district"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(!!_vm.church_area ? 'Select church district' : 'Select church area first'))])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,825554342)})],1):_vm._e(),(_vm.church_level === 'assembly')?_c('b-form-group',{attrs:{"label":"Assembly","label-for":"assembly"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Assembly"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,false,2843020057)},[_c('validation-provider',{attrs:{"name":"assembly","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"assembly","loading":_vm.loading,"clearable":false,"options":_vm.churchAssemblyOptions,"reduce":function (val) { return val.value; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.church_assembly),callback:function ($$v) {_vm.church_assembly=$$v},expression:"church_assembly"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(!!_vm.church_district ? 'Select church assembly' : 'Select church district first'))])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1737734854)})],1):_vm._e()],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('h3',{staticClass:"mb-2 mt-5"},[_vm._v(" Bank Information ")]),_c('b-form-group',{attrs:{"label":"Bank Country","label-for":"bank_level"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Bank Country"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"bank_country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"bank_country","loading":_vm.loading,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.countryOptions,"reduce":function (val) { return val.value; },"clearable":false},model:{value:(_vm.bank_country),callback:function ($$v) {_vm.bank_country=$$v},expression:"bank_country"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Select bank country")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Bank Name","label-for":"bank_name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Bank Name"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"bank_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"bank_name","loading":_vm.loading,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.bankOptions,"reduce":function (val) { return val.value; },"clearable":false},model:{value:(_vm.bank_code),callback:function ($$v) {_vm.bank_code=$$v},expression:"bank_code"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(!!_vm.bank_country ? 'Select bank name' : 'Select bank country first'))])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Account / Mobile Money Number","label-for":"bank_account_number"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Account / Mobile Money Number"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Account / Mobile Money Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"custom-input"},[_c('span',{staticClass:"leading"},[_c('feather-icon',{attrs:{"icon":"BriefcaseIcon","size":"20"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bank_account_number),expression:"bank_account_number"}],attrs:{"id":"bank_account_number","placeholder":"Enter your Account / Mobile Money Number Here"},domProps:{"value":(_vm.bank_account_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.bank_account_number=$event.target.value}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"d-flex my-1 mt-5 justify-content-between align-items-center"},[_c('div',[_c('b-button',{staticClass:"py-1",attrs:{"type":"submit","variant":"primary","block":""}},[_vm._v(" Proceed "),_c('feather-icon',{attrs:{"icon":"ChevronRightIcon"}})],1)],1),_c('b-card-text',{staticClass:"text-right mt-0 text-danger cursor-pointer",on:{"click":_vm.logout}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"LogOutIcon"}}),_c('span',[_vm._v("Logout")])],1)],1)],1)],1)],1)])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }